$lightgreen: #313630;
//IRANSansX-Regular.woff
@font-face {
  font-family: 'IRANSansX-Regular';
  src: url('../public/fonts/IRANSansX-Regular.woff') format('woff');
  /* Add more src declarations for different font formats if needed */
}

.app {
  display: flex;
  justify-content: center;
  .container {
    width: 100%;

    
    .iranSans {
      font-family: 'IRANSansX-Regular';
      /* Add any other custom styles for Typography here */
    }

    .link {
      text-decoration: none;
      color: inherit;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    //Dashboard
    .dashboard {
      display: flex;

      .sidebar {
        flex: 0.5;
        display: flex;
        flex-direction: column;
      }
      .dashboardbody {
        flex: 6;
        display: flex;
        flex-direction: column;
      }
    }

    //Login & Register
    .auth {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;
      background-color: $lightgreen;
      h1 {
        font-size: 20px;
        color: teal;
        margin-bottom: 20 px;
      }
      form {
        display: flex;
        flex-direction: column;
        padding: 10px;
        background-color: white;
        width: 200px;
        gap: 20px;

        input {
          padding: 10px;
          border: none;
          border-bottom: 1px solid gray;
        }

        button {
          padding: 10px;
          border: none;
          color: white;
          background-color: teal;
          cursor: pointer;
        }

        p {
          font-size: 12px;
          color: red;
          text-align: center;
        }

        span {
          font-size: 12px;
          text-align: center;
        }
      }
    }

    //Navbar
    .Appbar {
      margin-bottom: 20px;
      .logo {
        img {
          width: 20%;
        }
      }
    }

    //Footer
    footer {
      margin-top: 50px;
      background-color: $lightgreen;
      display: flex;
      align-items: center;
      justify-content: center;

      .typography {
        color: white;
        font-family: 'IRANSansX-Regular';

      }

      img {
        height: 50px;
      }
    }
    //Home
    .home {
      .posts {
        margin-top: 50px;
        display: flex;

        gap: 150px;
        .post {
          display: flex;
          gap: 100px;

          .img {
            flex: 2;
            img {
              width: 100%;
              max-height: 400px;
              object-fit: cover;
              border-radius: 4%;
            }
          }

          .content {
            flex: 3;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }
    }

    //Single
    .single {
      display: flex;
      width: 100%;
      gap: 4px;

      @media (max-width: 600px) {
        flex-direction: column;
        & > :nth-child(2) {
          border-top: 2px solid #3498db;
          padding-top: 10px;
          padding-right: 5px;
          margin-top: 20px;
          
      }
    }

      .content {

        img {
          width: 100%;
          max-height: 400px;
        }

        .user {
          
          gap: 10px;
          font-size: 14px;
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .title {
          font-family: 'IRANSansX-Regular';
  
        }

        .desc{
          font-family: 'IRANSansX-Regular';
        }

        p {
          line-height: 30px;
        }
      }

      .menu {
        flex: 2;
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        gap: 25px;

        h1 {
          font-size: 40px;
          color: #555;
        }

        .post {
          display: flex;
          flex-direction: column;
          gap: 10px;

          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
          }

          h2 {
            color: #555;
          }

        }
      }
    }
    //write
    .add {
      margin-top: 20px;
      display: flex;
      gap: 20px;

      .content {
        flex: 5;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-right: 12px;

        input {
          padding: 10px;
          border: 1px solid lightgray;
        }

        .editorContainer {
          border: 1px solid lightgray;

          .editor {
            border: none;
          }
        }
      }

      
    }
  }
}
